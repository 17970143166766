<template>
  <div>
    <el-form :model="form"
             ref="formRef"
             label-width="100px">
      <el-row :gutter="10"
              style="padding-bottom:10px">
        <el-col :span="6">
          <el-form-item label="起始时间">
            <el-date-picker v-model="form.timeBegin"
                            type="datetime"
                            style="width:100%"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="截止时间">
            <el-date-picker v-model="form.timeEnd"
                            type="datetime"
                            style="width:100%"
                            default-time="23:59:59"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="选择日期时间" />
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-button type="primary"
                     @click="search">提交</el-button>
          <!-- <el-button type="primary"
                     @click="exportExcel">导出</el-button> -->
        </el-col>
      </el-row>
    </el-form>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
// import { now_month_time, nowDateTime } from '@/utils/date.js'
export default {
  data () {
    return {
      tableData: [],
      options: [],
      form: {},
      page: 1,
      size: 10,
      totalElements: 0,
      pageName: 'lutiAdmin_admin_teachingMaterials_info',
      tableColumns: [
        { prop: 'edu_user_id', align: 'center', label: '编号', width: '' },
        { prop: 'subject_name', align: 'center', label: '科目', width: '' },
        { prop: 'edu_user_name', align: 'center', label: '用户名', width: '' },
        { prop: 'grade_name', align: 'center', label: '年级', width: '' },
        { prop: 'lecture_turn_name', align: 'center', label: '讲义轮次', width: '' },
        { prop: 'lecture_category_name', align: 'center', label: '讲义类别', width: '' },
        { prop: 'lecture_name', align: 'center', label: '讲义名称', width: '' },
        { prop: 'lecture_file', align: 'center', label: '讲义文件名', width: '' },
        { prop: 'lecture_questions', align: 'center', label: '关联题目ID', width: '' },
        { prop: 'create_time', align: 'center', label: '上传时间', width: '' },
        { prop: 'edit_time', align: 'center', label: '修改时间', width: '' },
        // {
        //   prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
        //     { content: '查看讲义', type: 'look', event: 'showForm' },
        //   ]
        // },
      ]
    }
  },
  mounted () {
    this.setTablesColums()
    this.form = JSON.parse(this.$route.query.form)
    this.search()
  },
  methods: {
    search () {
      this.$refs.pagination.toFirstPage()
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      console.log(this.form)
      if (!this.form.edu_user_id) return
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v2/stats/lecture_detail',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list.map(item => {
          item.edu_user_name = this.form.edu_user_name
          return item
        })
        this.totalElements = res.data.count
      })
    },
    showForm () { }

  }
}
</script>

<style lang="scss" scoped>
</style>